@import '../../../assets/styles/variables';

.header-transparent {
  background: transparent;

  img {
    filter: invert(1);
  }
}

.header-disabling {
  @include for-specific-size(1100px) {
    display: none !important;
  }
}

.header-primary {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 177px;
  @include for-specific-size(1100px) {
    flex: 0 0 65px;
  }

  &__logo {
    width: 100px;
    height: 30px;

    @include for-specific-size(1100px) {
      width: 57.31px;
      height: 16.98px;
    }
  }
}

.header-mobile_menu {
  display: none;

  &-key {
    display: none;
    width: 40%;

    @include for-specific-size(1100px) {
      display: block;
    }
  }

  &-icon {
    @include for-specific-size(1100px) {
      margin: auto 5% auto auto;
    }

    span {
      @include for-specific-size(1100px) {
        display: block;
        margin: auto 0px 6px auto;
        width: 34px;
        height: 5px;
        background: white;
      }
    }
  }

  &-icon2 {
    @include for-specific-size(1100px) {
      margin: auto 5% auto auto;
    }

    span {
      @include for-specific-size(1100px) {
        display: block;
        margin: auto 0px 6px auto;
        width: 34px;
        height: 5px;
        background: black;
      }
    }
  }
}

.header {
  display: flex;
}

.header-big-orange,
.header-big-white {
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 360px;
  display: flex;
  justify-content: center;

  @include for-specific-size(1100px) {
    height: 160px;
  }

  @include for-size(phone) {
    height: 120px;
  }
}

.header-big-white {
  background: white;
}

.header-big-orange {
  background: linear-gradient(90.35deg, #0296ff 0.09%, #D74341 99.49%);
}

.header-mobile_menu_2 {
  display: none;

  &-key {
    display: none;
    width: 40%;

    @include for-specific-size(768px) {
      display: block;
    }
  }

  &-icon {
    @include for-specific-size(768px) {
      margin: auto 5% auto auto;
    }

    span {
      @include for-specific-size(768px) {
        display: block;
        margin: auto 0px 6px auto;
        width: 34px;
        height: 5px;
        background: white;
      }
    }
  }

  &-icon2 {
    @include for-specific-size(768px) {
      margin: auto 5% auto auto;
    }

    span {
      @include for-specific-size(768px) {
        display: block;
        margin: auto 0px 6px auto;
        width: 34px;
        height: 5px;
        background: black;
      }
    }
  }
}