@import '../../../../assets/styles/variables';

.modal_default-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border-radius: 24px;
  margin: 0 auto;
  width: 320px;

  @include for-size(phone) {
    width: 80%;
    max-width: 320px;
    padding: 70px 20px 30px;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    @include for-size(phone) {
      flex-wrap: nowrap;
    }
  }
}

.modal_credit-card {
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  background: #FFFFFF;
  padding: 10px 15px;
  border-radius: 47px;
  display: flex;
  flex-direction: column;

  input {
    background: none;
    color: #000000;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    &:focus {
      border-bottom: 1px solid #0296ff !important;
    }
  }

  @include for-size(desktop-laptop) {
    max-width: 100%;
    width: 100%;
  }

  &-title {
    font-weight: bold;
    font-size: 48px;
    line-height: 55px;
    color: rgba(18, 18, 29, 0.8);
    text-align: center;
    margin: 0;
    @include for-size(tablet-phone) {
      display: none;
    }
  }

  &__container {
    margin-top: 35px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__item {
    position: relative;

    &-error {
      position: absolute;
      bottom: -20px;
      color: #f22;
    }

    &-label {
      margin-bottom: 7px;
      font-size: 16px;
      line-height: 24px;
      color: rgba(18, 18, 29, 0.3);
    }

    &-input {
      width: 100%;
      border: none;
      border-bottom: 1px solid rgba(18, 18, 29, 0.1);
      background: #f2f2f2;
      outline: none;
      color: rgba(18, 18, 29, 0.3);
      padding: 0;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px #f2f2f2 inset !important;
        background: #f2f2f2 !important;
      }
    }

    &-icon {
      position: absolute;
      width: 17px;
      height: 16px;
      top: 35px;
      right: 5px;
      cursor: pointer;

      path {
        transition: .3s all;
      }

      path:first-child {
        opacity: 1;
      }

      path:last-child {
        opacity: 0;
      }
    }

    &-icon-shown {
      path:first-child {
        opacity: 0;
      }

      path:last-child {
        opacity: 1;
        fill-opacity: 1;
      }
    }

    &-card {
      width: 100%;
    }
  }

  &__buttons {
    display: flex;
    column-gap: 10px;
    margin-top: 40px;

   button[type='submit'] {
      background: #0296ff;
    }
  }
}

