.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #00000084;
  z-index: 100;
  top: 0;
  left: 0;
  overflow: auto;

  &-close {
    display: none;
  }
}