@import '../../../assets/styles/variables';

.percent-circle {
  position: relative;
  width: 90px;
  height: 90px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  @include for-specific-size(576px){
    width: 40px;
    height: 40px;
  }
  // padding-left: 10px;

  // @include for-size(tablet-phone) {
  //   width: 130px;
  //   height: 130px;
  // }

  &__text {
    padding-right: 4px;
    position: absolute;
    font-family: 'SF Pro Display', sans-serif;
    font-style: normal;
    font-size: 12px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%);
    font-weight: 700;
    color: #000000;

    @include for-size(tablet-phone) {
      padding-bottom: 0;
      font-size: 11px !important;
    }

    @include for-specific-size(768px) {
      font-size: 11px !important;
    }
  }

  &__chart.color &__body {
    stroke: #0296ff;
  }

  &__background {
    fill: none;
    stroke: #eee;
    stroke-width: 4;
  }

  &__body {
    fill: none;
    stroke-width: 4;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
  }
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
