@import '../assets/styles/variables';

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  touch-action: pan-x pan-y;
}

#root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: inherit;
  background: #F9F9F9;
  box-sizing: border-box;
  font-family: 'SFProDisplay-Regular', sans-serif;
}

html,
body {
  height: 100vh;
}

select,
option {
  box-sizing: border-box !important;
}

option {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.next_hint {
  pointer-events: all;
  position: fixed;
  padding: 20px 50px 50px;
  right: 0;
  top: 0;
  z-index: 999;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.88);
  cursor: pointer;

  &:before {
    pointer-events: all;
    z-index: -1;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    content: '';
    background: rgba(red, 0);
    cursor: default;
  }
}

.hint {
  z-index: 5;

  &:before {
    position: absolute;
    content: ' ';
    width: calc(100% + 15px);
    height: calc(100% + 15px);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    border-radius: 15px;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, .8);
  }
}

.week-navigation__hint,
.player__hint,
.alt__hint,
.view-plan__hint,
.rate-popup__hint {
  &:after {
    text-align: left;
    box-sizing: border-box;
    position: absolute;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
    z-index: -1;
    white-space: pre;
    transition: .3s all;

    @include for-specific-size(720px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.week-navigation__hint {
  &:after {
    content: 'This is your calendar for the week.';
    background: url('../assets/images/vectors/arrow-up1.svg') right center no-repeat;
    bottom: -150%;
    left: -75%;
    padding-right: 50px;
    padding-top: 100px;

    @include for-specific-size(1275px) {
      left: -50%;
    }

    @include for-specific-size(1090px) {
      left: -25%;
    }

    @include for-specific-size(720px) {
      left: -50px;
      bottom: -140%;
    }

    @include for-specific-size(602px) {
      left: 20%;
    }

    @include for-specific-size(550px) {
      content: 'This is your calendar for the week.';
      left: 0%;
      bottom: -140%;
    }

    @include for-specific-size(390px) {
      left: -3%;
    }
  }
}

.player__hint {
  &:after {
    content: 'Here you will find your\a workout for the day.';
    background: url('../assets/images/vectors/arrow-down1.svg') left center no-repeat;
    top: -150px;
    padding-top: 50px;
    padding-left: 50px;
    left: 40%;
    padding-bottom: 100px;

    @include for-specific-size(720px) {
      left: 20%;
    }

    @include for-specific-size(520px) {
      left: 5%;
    }
  }
}

.alt__hint {
  &:after {
    content: "If you're short on time,\athere's a short workout nearby.";
    background: url('../assets/images/vectors/arrow-up2.svg') right center no-repeat;
    bottom: -80px;
    left: -70%;
    padding-right: 70px;

    @include for-specific-size(1440px) {
      left: -100%;
    }

    @include for-specific-size(1120px) {
      left: -130%;
    }

    @include for-specific-size(1024px) {
      left: 30%;
      bottom: -90px;
      padding-right: 60px;
      padding-top: 50px;
    }

    @include for-specific-size(510px) {
      left: 10%;
    }

    @include for-specific-size(420px) {
      left: 0;
    }
  }
}

.view-plan__hint {
  &:after {
    content: 'Here you can see information\a about your Plan.';
    background: url('../assets/images/vectors/arrow-down2.svg') right center no-repeat;
    top: -200%;
    left: -50%;
    padding-bottom: 100px;
    padding-right: 50px;

    @include for-specific-size(1440px) {
      left: -100%;
    }

    @include for-specific-size(1024px) {
      left: 30%;
    }

    @include for-specific-size(540px) {
      left: 10%;
    }

    @include for-specific-size(420px) {
      left: 0;
    }
  }
}

.rate-popup__hint {
  &:after {
    content: 'Rate your workouts after you finish.\a Your feedback will help us fine tune your plan.';
    background: url('../assets/images/vectors/arrow-up1.svg') right center no-repeat;
    top: 150%;
    left: -50%;
    padding-top: 30px;
    padding-right: 50px;

    @include for-specific-size(767px) {
      content: 'Rate your workouts after you finish.\a Your feedback will help us\a fine tune your plan.';
      padding-top: 80px;
      top: 50%;
    }

    @include for-specific-size(720px) {
      padding-top: 80px;
      left: -20%;
    }

    @include for-specific-size(460px) {
      padding-right: 30px;
      left: -10%;
    }

    @include for-specific-size(410px) {
      content: 'Rate your workouts after you finish.\a Your feedback will help us\a fine tune your plan.';
      padding-top: 135px;
      top: -35%;
      background-size: 10%;
      padding-right: 0px;
    }
  }
}

* {
  &::-webkit-scrollbar {
    width: 4px;
    height: 2px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #EDF3F4;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #0296ff;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}