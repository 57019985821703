@import './normalize.scss';
@import './_mixin.scss';
@import './variables';

.content-container {
    box-sizing: border-box;
    width: 100%;
    flex: 1 1 100%;
    padding: 30px 70px 10px;
    display: flex;
    flex-direction: column;

    @include for-size(desktop-laptop) {
        padding: 30px 20px 10px 20px;
    }

    @include for-size(tablet-phone) {
        padding: 0;
    }
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type='number'] {
    -moz-appearance: textfield;
}
input[type='number']:hover,
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
body {
    overflow-x: hidden !important;
}
