@import '../../../../assets/styles/variables';

.before-video {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  padding: 45px 50px 45px 50px;
  max-width: 440px;
  border-radius: 24px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__close {
    position: absolute;
    right: 32px;
    top: 32px;
    cursor: pointer;
    height: 16px;
  }

  @include for-size(tablet-phone) {
    width: 95%;
    padding: 40px 25px 40px;
  }

  &-title {
    font-weight: bold;
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 28px;
    color: #12121D;
    text-align: center;

    @include for-size(tablet-phone) {
      text-align: center;
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 24px;
    }
  }

  &-description {
    font-size: 16px;
    line-height: 24px;
    color: rgba(18, 18, 29, 0.8);
    text-align: center;
    @include for-size(tablet-phone) {
      text-align: center;
    }
  }

  &__buttons_content {
    margin-bottom: 25px;
    margin-top: 20px;
    display: flex;
    column-gap: 20px;
    justify-content: center;

    @include for-size(tablet-phone) {
      flex-direction: column-reverse;
      align-items: center;
      max-width: 80%;
      margin-left: 10%;
      margin-bottom: 0;
    }

    button {
      margin: 0;
      color: #12121D;
      padding: 0;
      height: 42px;

      &:first-child {
        background: transparent;
        min-width: auto;


        @include for-size(tablet-phone) {
          display: flex;
          justify-content: center;
          height: 40px !important;
        }

        &:hover {
          background: none;
        }
      }

      &:last-child {
        flex: 0 0 165px;
        background: black;
        color: white;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        @include for-size(tablet-phone) {
          flex: 0 0 45px;

        }
      }
    }
  }

  &__checkbox {
    display: flex;
    align-items: center;
    column-gap: 10px;
    justify-content: center;

    @include for-specific-size(1024px) {
      margin-top: 8px;
    }


    small {
      color: #6c6c72;
    }

    span {
      position: relative;
      border: 1px solid rgba(#6c6c72, .4);
      height: 15px;
      width: 15px;
      border-radius: 4px;

      &:after {
        position: absolute;
        content: ' ';
        border-right: 2px solid white;
        border-bottom: 2px solid white;
        height: 50%;
        width: 20%;
        left: 50%;
        top: 50%;
        transform: translate(-40%, -63%) rotate(45deg);
      }
    }

    input:checked + span {
      background: #0296ff;
    }

    input {
      display: none;
    }
  }
}
