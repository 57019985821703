@import '../../../../assets/styles/variables';

.default-modal-close {
  cursor: pointer;
}

.free-default-modal {
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 85px 30px 45px 30px;
  border-radius: 24px;
  margin: 0 auto;
  width: auto;

  @include for-size(phone) {
    max-width: 400px;
    padding-top: 25px;
  }
}

a {
  color: blue;
  cursor: pointer;
}

.modal-body {
  display: flex;

  @include for-size(phone) {
    display: inline;
  }

  padding-bottom: 20px;
}

.left-content {
  width: 350px;
  margin-right: 20px;
  justify-items: center;

  @include for-size(phone) {
    margin-right: 0px;
    margin-bottom: 20px;
  }
}

.modal-title {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  font-family: "SFProDisplay-Bold", sans-serif;
  margin-top: 30px;

  @include for-size(phone) {
    margin-top: 10px;
  }
}

.modal-title-2 {
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-family: "SFProDisplay-Bold", sans-serif;
}

.modal-content-1 {
  text-align: center;
  margin-top: 40px;
  line-height: 150%;
}

.modal-content-2 {
  line-height: 150%;
  text-align: center;
}

.modal-content-3 {
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
}

.modal-content-4 {
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

.right-content {
  // @include for-size(phone) {
  //   display: none;
  // }
}

.popup-select-submit {
  padding: 9px 25px !important;
  width: auto;
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  background: black;
  margin-left: auto;
  margin-right: auto;
  font-family: 'SFProDisplay-Regular', sans-serif;
}

.free-popup-select-submit-not-now {
  background: black;
  font-family: 'SFProDisplay-Regular', sans-serif;
  font-weight: 400;
  padding: 13px 0 !important;
  width: 250px;
  text-align: center;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  color: white;
  border: none;
}